import React, {useEffect, useState} from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    NumberInput,
    BooleanInput,
    ReferenceInput,
    SelectInput,
    FormDataConsumer,
    useGetOne,
    Loading,
    Error,
    ImageInput,
    ImageField,
    required,
    email,
    minLength
} from 'react-admin';
import {TechnicalUserRoles, UserAuthorizedSections, UserRoles} from '../common.js'
import AvatarField from "../../../atoms/avatar-field";
import {useParams} from "react-router-dom";
import {SelectArrayInput} from "ra-ui-materialui/dist/cjs/input/SelectArrayInput";
import {isObject} from "lodash"

const ClientEdit = (props) => {
    const { id } = useParams();
    const { data, isLoading, error } = useGetOne('users', { id },{staleTime: 60000});

    const [technicalSelectionRole, setTechnicalSelectionRole] = useState(null);

    useEffect(() => {
        if (data && data.technical_selection_user && data.technical_selection_user.role) {
            setTechnicalSelectionRole(data.technical_selection_user.role);
        } else {
            setTechnicalSelectionRole(null);
        }
    }, [data]);

    if (isLoading) return <Loading />;
    if (error) return <Error />;

    return (
    <Edit {...props} redirect={"show"}>
            <SimpleForm >
                <TextInput source="name" validate={[required()]} />
                <TextInput source="surname" validate={[required()]} />
                <TextInput source="email" validate={[required(), email()]}  />
                <TextInput source="password" type="password" validate={[minLength(8)]}/>
                <BooleanInput source="force_reset_password" />

                <ReferenceInput
                    source="client.id"
                    reference="clients"
                    perPage={100}>
                    <SelectInput
                        source="name"
                        optionText="name" />
                </ReferenceInput>

                <AvatarField  record={data} source="avatar" resource="users" />
                <Separator />

                <ImageInput source="imageBase64" accept="image/*">
                    <ImageField source="src" title="title" multiple={false} />
                </ImageInput>

                <BooleanInput source="technical_selection_user.enabled"/>
                <FormDataConsumer>
                    {// eslint-disable-next-line no-unused-vars
                        ({ formData }) => (
                            formData.technical_selection_user &&
                            formData.technical_selection_user.enabled &&
                            <>
                                <div className="child-items">
                                    <SelectInput source="technical_selection_user.role" validate={[required()]}  choices={TechnicalUserRoles}
                                        onChange={(item) => {
                                            if (item && item.target && item.target.value) {
                                                setTechnicalSelectionRole(item.target.value);
                                            }
                                        }}
                                    />
                                </div>
                                {technicalSelectionRole && technicalSelectionRole === 'BASIC' && <div className="child-items">
                                    <SelectArrayInput source="user_authorized_sections"
                                        validate={[required()]} choices={UserAuthorizedSections}
                                        format={sections => sections.map(item => {
                                            if (isObject(item)) {
                                                return item.section;
                                            }
                                            return item;
                                        })}
                                    />
                                </div>}
                                <div className="child-items">
                                    <TextInput fullWidth={true} multiline source="technical_selection_user.filters_default" label="filtersUserDefault"/>
                                </div>
                            </>
                        )}
                </FormDataConsumer>
                <BooleanInput source="rrhh_user.enabled"/>
                <FormDataConsumer>
                    {({ formData }) => (
                        formData.rrhh_user &&
                        formData.rrhh_user.enabled &&
                        <>
                            <div className="child-items">
                                <SelectInput source="rrhh_user.role" validate={[required()]}  choices={TechnicalUserRoles} />
                            </div>
                            <div className="child-items">
                                <TextInput fullWidth={true} multiline source="rrhh_user.filters_default" label="filtersUserDefault"/>
                            </div>
                        </>
                    )}
                </FormDataConsumer>
                <BooleanInput source="user_on_boarding.enabled"/>
                <FormDataConsumer>
                    {// eslint-disable-next-line no-unused-vars
                        ({ formData }) => (
                            formData.user_on_boarding &&
                            formData.user_on_boarding.enabled &&
                            <>
                                <div className="child-items">
                                    <SelectInput source="user_on_boarding.role"  validate={[required()]}  choices={UserRoles} />
                                </div>
                                <div className="child-items">
                                    <TextInput fullWidth={true} multiline source="user_on_boarding.filters_default" label="filtersUserDefault"/>
                                </div>
                            </>
                        )}
                </FormDataConsumer>
                <BooleanInput source="user_feedback.enabled"/>
                <FormDataConsumer>
                    {// eslint-disable-next-line no-unused-vars
                        ({ formData }) => (
                            formData.user_feedback &&
                            formData.user_feedback.enabled &&
                            <>
                                <div className="child-items">
                                    <SelectInput source="user_feedback.role" validate={[required()]}  choices={UserRoles} />
                                </div>
                                <div className="child-items">
                                    <TextInput fullWidth={true} multiline source="user_feedback.filters_default" label="filtersUserDefault"/>
                                </div>
                            </>
                        )}
                </FormDataConsumer>

                <BooleanInput source="user_exit_interview.enabled"/>
                <FormDataConsumer>
                    {// eslint-disable-next-line no-unused-vars
                        ({ formData }) => (
                            formData.user_exit_interview &&
                            formData.user_exit_interview.enabled &&
                            <>
                                <div className="child-items">
                                    <SelectInput source="user_exit_interview.role" validate={[required()]}  choices={UserRoles} />
                                </div>
                                <div className="child-items">
                                    <TextInput fullWidth={true} multiline source="user_exit_interview.filters_default" label="filtersUserDefault"/>
                                </div>
                            </>
                        )}
                </FormDataConsumer>
            </SimpleForm>
        </Edit>
    );
};

const Separator = () => <div style={{ height: 5 }} />;

export default ClientEdit;
