import * as React from 'react';
import i18n from "../../../assets/i18n";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import * as XLSX from 'xlsx';
import { Description } from '@mui/icons-material';
import { Button } from 'react-admin';

export default function DailyCandidatesByClient({ data: statistics }) {
  const dinamicDayNameColumns = statistics?.column_titles ? Object.values(statistics?.column_titles) : []
  const dinamicDayKeyColumns = statistics?.column_titles ? Object.keys(statistics?.column_titles) : []
  const avgCounter = statistics.avg_counter
  const from = statistics.from
  const to = statistics.to
  const clientStatisticRows = statistics?.rows ? Object.values(statistics?.rows) : []

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet([]);
    const header = ['Client Name', ...dinamicDayNameColumns, 'Average'];
    XLSX.utils.sheet_add_aoa(worksheet, [header], { origin: 'A1' });

    clientStatisticRows.forEach(clientStats => {
      const row = [];
      row.push(clientStats?.client_name ?? '-');
      let total = 0;
      dinamicDayKeyColumns.forEach(dayKey => {
        const amount = dayKey in clientStats.column_data ? clientStats.column_data[dayKey] : 0;
        row.push(amount);
        total += amount;
      });
      row.push(Math.floor(total / avgCounter));
      XLSX.utils.sheet_add_aoa(worksheet, [row], { origin: -1 });
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Daily Candidates');
    XLSX.writeFile(workbook, `Daily_Candidates_${from}_to_${to}.xlsx`);
  };

  return (
    <>
      <Button variant="contained" onClick={exportToExcel} sx={{ marginBottom: 2 }}>
        <Description /> Exportar a Excel
      </Button>
      <TableContainer sx={{overflowY: 'auto', maxWidth: '100%'}} component={Paper}>
        <Table size="small" aria-label="a dense table" sx={{minWidth: 'max-content'}}>
          <TableHead>
            <TableRow>
              <TableCell colSpan={avgCounter + 3} align='center'><strong>Candidatos/Día ({from} al {to})</strong></TableCell>
            </TableRow>
            <TableRow>
              <TableCell></TableCell>
              {dinamicDayNameColumns.map(function (text) {
                return (<TableCell><strong>{text}</strong></TableCell>)
              })}
              <TableCell><strong>Average</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clientStatisticRows.map(function (clientStats) {
              let total = 0
              return (
                <TableRow>
                  <TableCell>{ clientStats?.client_name ?? '-' }</TableCell>
                  {dinamicDayKeyColumns.map(function (dayKey) {
                    let amount = dayKey in clientStats.column_data ? clientStats.column_data[dayKey] : 0

                    total += amount

                    return (<TableCell>{amount}</TableCell>)
                  })}
                  <TableCell>{ Math.floor(total / avgCounter) }</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}