import React, { useState } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ImageField,
  ImageInput,
  BooleanInput,
  SelectInput,
  FormDataConsumer,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  NumberInput,
  regex,
  required,
} from "react-admin";
import { MODULE_IDS } from "../../../../config/api";
import { ColorInput } from "react-admin-color-picker";
import { WhatsAppSenderTestCallback } from "../common/whatsapp";
import { Snackbar, Button } from "@mui/material";
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { ClientFeatures, Languages } from "../common";

const ClientCreate = (props) => {

  const [submitIsDisabled, setSubmitIsDisabled] = useState(false);
  const [whatsappTestSnackbar, setWhatsappTestSnackbar] = useState({});
  const whatsAppPhoneFieldId = 'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.whatsapp_test_phone';
  const [count, setCount] = useState(0);

  const sendWhatsAppTest = async () => {
    setSubmitIsDisabled(true);
    let lang = document.getElementById('language').value;
    let whatsappSenderId = document.getElementById('module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.whatsapp_sender_id').value;
    let wabaKey = document.getElementById('module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.waba_key').value;
    let whatsappTemplate = document.getElementById('module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.whatsapp_template').value;
    let whatsappTo = document.getElementById(whatsAppPhoneFieldId).value;

    const launchTest = await WhatsAppSenderTestCallback(() => {
      return {
        whatsappSenderId: whatsappSenderId,
        wabaKey: wabaKey,
        whatsappTemplate: whatsappTemplate,
        whatsappTo: whatsappTo,
        lang: lang
      };
    });
    if (launchTest) {
      setSubmitIsDisabled(false);
      setWhatsappTestSnackbar({ message: 'WhatsApp test successful', type: 'success' });
    } else {
      setWhatsappTestSnackbar({ message: 'whatsapp.test.error', type: 'error' });
    }
    setTimeout(() => {
      setWhatsappTestSnackbar({});
    }, 3000);
  }

  return (
    <Create redirect="show" >
      <SimpleForm>

        <Grid container spacing={2} >
          <Grid item xs="auto" >
            <Box sx={{ width: 500, maxWidth: '100%' }}>
              <TextInput fullWidth source="name" validate={[required()]} />
              <TextInput fullWidth source="legal_name" />
              <TextInput fullWidth source="nif" />
              <TextInput fullWidth source="address" />
              <TextInput fullWidth source="billing_address" />
              <TextInput fullWidth source="city" />
              <TextInput fullWidth source="cp" />
              <TextInput fullWidth source="province" />
              <TextInput fullWidth source="country" />
              <BooleanInput source="is_velora" label="Cliente Velora" />
              <BooleanInput source="is_velora_assesment" label="Velora Assesment" />
            </Box>
          </Grid>
          <Grid item xs="auto" >
            <Box sx={{ width: 500, maxWidth: '100%' }}>
              <TextInput fullWidth source="email" validate={[required()]} />
            </Box>
          </Grid>
          <Grid item xs="auto" >
            <Box sx={{ width: 500, maxWidth: '100%' }}>
              <TextInput fullWidth source="email_from" validate={[required()]} />
            </Box>
          </Grid>
        </Grid>

        <SelectInput
          source="lang"
          choices={Languages}
          inputProps={{ id: 'language' }}
        />

        <BooleanInput source="sms_available" />
        <TextInput source="sms_prefix" validate={regex(/^\+.+/)} />
        <TextInput source="sendgrid_token" />
        <NumberInput source="data_retention_months" validate={[required()]}/>

        <AutocompleteArrayInput
          source="features"
          choices={ClientFeatures}
        />

        <NumberInput source="max_users" validate={[required()]} defaultValue={199}/>

        <ColorInput source="text_color_principal" validate={[required()]} />
        <ColorInput source="text_color_secondary" validate={[required()]} />
        <ColorInput source="background_color" validate={[required()]} />

        <ImageInput source="imageBase64" accept="image/*" >
          <ImageField source="src" title="title" multiple={false} />
        </ImageInput>

        <Separator />

        <BooleanInput source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.enabled'} />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              formData["module_" + MODULE_IDS.ID_TALENT_ACQUISITION] &&
              formData["module_" + MODULE_IDS.ID_TALENT_ACQUISITION]
                .enabled && (
                <div className="child-items">
                  <ReferenceArrayInput
                    {...rest}
                    reference="inboxes"
                    source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.inbox_fields'}
                    perPage={1000}
                    key={`inboxes_${count}`}
                    filter={{ 'moduleType.id': MODULE_IDS.ID_TALENT_ACQUISITION }}
                    onChange={() => setTimeout(() => setCount(count + 1), 250)}
                    sort={{ field: 'name', order: 'ASC' }}
                  >
                    <AutocompleteArrayInput
                     optionText="name"
                     filterToQuery={(searchText) => ({ name: searchText })}
                    />
                  </ReferenceArrayInput>

                  <Grid container spacing={2} >
                    <Grid item xs="auto" >
                      <NumberInput {...rest} source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.minimum_percentage_suitable'} validate={[required()]} />
                    </Grid>
                    <Grid item xs="auto" >
                      <NumberInput {...rest} source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.skill_weight'} validate={[required()]} />
                    </Grid>
                    <Grid item xs="auto" >
                      <NumberInput {...rest} source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.profile_weight'} validate={[required()]} />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} >
                    <Grid item xs="auto" >
                      <Box sx={{ width: 500, maxWidth: '100%' }}>
                        <TextInput fullWidth  {...rest} source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.token_sendgrid_email_bot_starter_template'} />
                      </Box>
                    </Grid>
                    <Grid item xs="auto"  >
                      <Box sx={{ width: 500, maxWidth: '100%' }}>
                        <TextInput fullWidth {...rest} source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.token_sendgrid_email_bot_starter_repush_template'} />
                      </Box>
                    </Grid>
                    <Grid item xs="auto"  >
                      <Box sx={{ width: 500, maxWidth: '100%' }}>
                        <TextInput fullWidth {...rest} source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.token_sendgrid_email_bot_starter_recurrent_template'} />
                      </Box>
                    </Grid>

                  </Grid >

                  <Grid container spacing={2} >
                    <Grid item xs="auto"  >
                      <Box sx={{ width: 500, maxWidth: '100%' }}>
                        <TextInput fullWidth {...rest} source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.token_sendgrid_email_bot_feedback_template'} />
                      </Box>
                    </Grid>
                    <Grid item xs="auto" >
                      <Box sx={{ width: 500, maxWidth: '100%' }}>
                        <TextInput fullWidth {...rest} source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.token_sendgrid_chat_template'} />
                      </Box>
                    </Grid>
                    <Grid item xs="auto"  >
                      <Box sx={{ width: 500, maxWidth: '100%' }}>
                        <TextInput fullWidth {...rest} source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.token_sendgrid_citation_template'} />
                      </Box>
                    </Grid>

                  </Grid >

                  <Grid container spacing={2} >
                    <Grid item xs="auto"  >
                      <Box sx={{ width: 500, maxWidth: '100%' }}>
                        <TextInput fullWidth {...rest} source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.token_sendgrid_email_notice_template'} label="resources.clients.fields.module_talent_acquisition.token_sendgrid_email_notice_template" />
                      </Box>
                    </Grid>
                    <Grid item xs="auto"  >
                      <Box sx={{ width: 500, maxWidth: '100%' }}>
                        <TextInput fullWidth {...rest} source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.stats_filters_available'} label="resources.clients.fields.stats_filters_available" />
                      </Box>
                    </Grid>
                    <Grid item xs="auto"  >
                      <Box sx={{ width: 500, maxWidth: '100%' }}>
                        <TextInput fullWidth
                          source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.schedules_available_to_notify_bot_starter'}
                          label="resources.clients.fields.module_talent_acquisition.schedules_available_to_notify_bot_starter"
                        />
                      </Box>
                    </Grid>
                  </Grid >

                  <TextInput
                    {...rest}
                    fullWidth
                    multiline
                    label="resources.clients.fields.module_talent_acquisition.sms_start_bot_template"
                    source={
                      "module_" +
                      MODULE_IDS.ID_TALENT_ACQUISITION +
                      ".sms_start_bot_template"
                    }
                    inputProps={{ maxLength: 140 }}
                  />
                  <TextInput
                    {...rest}
                    fullWidth
                    multiline
                    label="resources.clients.fields.module_talent_acquisition.sms_repush_bot_template"
                    source={
                      "module_" +
                      MODULE_IDS.ID_TALENT_ACQUISITION +
                      ".sms_repush_bot_template"
                    }
                    inputProps={{ maxLength: 140 }}
                  />
                  <TextInput
                    {...rest}
                    fullWidth
                    multiline
                    label="resources.clients.fields.module_talent_acquisition.sms_bot_starter_recurrent_template"
                    source={
                      "module_" +
                      MODULE_IDS.ID_TALENT_ACQUISITION +
                      ".sms_bot_starter_recurrent_template"
                    }
                    inputProps={{ maxLength: 140 }}
                  />
                  <TextInput
                    {...rest}
                    fullWidth
                    multiline
                    label="resources.clients.fields.module_talent_acquisition.sms_feedback_bot_template"
                    source={
                      "module_" +
                      MODULE_IDS.ID_TALENT_ACQUISITION +
                      ".sms_feedback_bot_template"
                    }
                    inputProps={{ maxLength: 140 }}
                  />


                  <Grid container spacing={2} >
                    <Grid item xs="auto"  >
                      <Box sx={{ width: 500, maxWidth: '100%' }}>
                        <NumberInput fullWidth {...rest} source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.update_candidature_in_days'} />
                      </Box>
                    </Grid>
                    <Grid item xs="auto"  >
                      <Box sx={{ width: 500, maxWidth: '100%' }}>
                        <TextInput fullWidth {...rest} source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.update_candidature_bot_url'} validate={regex(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/)} />
                      </Box>
                    </Grid>

                  </Grid >

                  <BooleanInput
                    {...rest}
                    source={
                      "module_" +
                      MODULE_IDS.ID_TALENT_ACQUISITION +
                      ".talent_clue_integrated"
                    }
                  />
                </div>
              )
            );
          }}
        </FormDataConsumer>

        <Separator />

        <BooleanInput
          source={"module_" + MODULE_IDS.ID_EMPLOYEE_LINE + ".enabled"}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              formData["module_" + MODULE_IDS.ID_EMPLOYEE_LINE] &&
              formData["module_" + MODULE_IDS.ID_EMPLOYEE_LINE].enabled && (
                <div className="child-items">
                  <ReferenceArrayInput
                    {...rest}
                    reference="inboxes"
                    source={
                      "module_" + MODULE_IDS.ID_EMPLOYEE_LINE + ".inbox_fields"
                    }
                    perPage={100}
                    filter={{ "moduleType.id": MODULE_IDS.ID_EMPLOYEE_LINE }}
                    onChange={() => setTimeout(() => setCount(count + 1), 250)}
                    sort={{ field: 'name', order: 'ASC' }}
                    >
                      <AutocompleteArrayInput
                       optionText="name"
                       filterToQuery={(searchText) => ({ name: searchText })}
                    />
                  </ReferenceArrayInput>
                  <Grid container spacing={2} >
                    <Grid item xs="auto"  >
                      <Box sx={{ width: 500, maxWidth: '100%' }}>
                        <TextInput fullWidth {...rest} source={'module_' + MODULE_IDS.ID_EMPLOYEE_LINE + '.sendgrid_process_new_template'} />
                      </Box>
                    </Grid>
                    <Grid item xs="auto"  >
                      <Box sx={{ width: 500, maxWidth: '100%' }}>
                        <TextInput fullWidth {...rest} source={'module_' + MODULE_IDS.ID_EMPLOYEE_LINE + '.sendgrid_process_status_changed_template'} />
                      </Box>
                    </Grid>
                    <Grid item xs="auto"  >
                      <Box sx={{ width: 500, maxWidth: '100%' }}>
                        <TextInput fullWidth {...rest} source={'module_' + MODULE_IDS.ID_EMPLOYEE_LINE + '.stats_filters_available'} label="resources.clients.fields.stats_filters_available" />
                      </Box>
                    </Grid>
                  </Grid >
                </div>
              )
            );
          }}
        </FormDataConsumer>

        <Separator />

        <BooleanInput
          source={"module_" + MODULE_IDS.ID_ON_BOARDING + ".enabled"}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              formData["module_" + MODULE_IDS.ID_ON_BOARDING] &&
              formData["module_" + MODULE_IDS.ID_ON_BOARDING].enabled && (
                <div className="child-items">
                  <ReferenceArrayInput
                    {...rest}
                    reference="inboxes"
                    source={
                      "module_" + MODULE_IDS.ID_ON_BOARDING + ".inbox_fields"
                    }
                    perPage={100}
                    sort={{ field: "name", order: "ASC" }}
                    filter={{ "moduleType.id": MODULE_IDS.ID_ON_BOARDING }}
                    onChange={() => setTimeout(() => setCount(count + 1), 250)}
                  >
                    <AutocompleteArrayInput
                      optionText="name"
                      filterToQuery={(searchText) => ({ name: searchText })}
                    />
                  </ReferenceArrayInput>

                  <Grid container spacing={2} >
                    <Grid item xs="auto"  >
                      <Box sx={{ width: 500, maxWidth: '100%' }}>
                        <TextInput fullWidth {...rest} source={'module_' + MODULE_IDS.ID_ON_BOARDING + '.sendgrid_process_new_template'} />
                      </Box>
                    </Grid>
                    <Grid item xs="auto"  >
                      <Box sx={{ width: 500, maxWidth: '100%' }}>
                        <TextInput fullWidth {...rest} source={'module_' + MODULE_IDS.ID_ON_BOARDING + '.sendgrid_process_status_changed_template'} />
                      </Box>
                    </Grid>
                    <Grid item xs="auto"  >
                      <Box sx={{ width: 500, maxWidth: '100%' }}>
                        <TextInput fullWidth {...rest} source={'module_' + MODULE_IDS.ID_ON_BOARDING + '.stats_filters_available'} label="resources.clients.fields.stats_filters_available" />
                      </Box>
                    </Grid>

                  </Grid >
                </div>
              )
            );
          }}
        </FormDataConsumer>

        <Separator />

        <BooleanInput
          source={"module_" + MODULE_IDS.ID_FEEDBACK + ".enabled"}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              formData["module_" + MODULE_IDS.ID_FEEDBACK] &&
              formData["module_" + MODULE_IDS.ID_FEEDBACK].enabled && (
                <div className="child-items">
                  <ReferenceArrayInput
                    {...rest}
                    reference="inboxes"
                    source={
                      "module_" + MODULE_IDS.ID_FEEDBACK + ".inbox_fields"
                    }
                    perPage={100}
                    sort={{ field: "name", order: "ASC" }}
                    filter={{ "moduleType.id": MODULE_IDS.ID_FEEDBACK }}
                    onChange={() => setTimeout(() => setCount(count + 1), 250)}
                  >
                    <AutocompleteArrayInput
                      optionText="name"
                      filterToQuery={(searchText) => ({ name: searchText })}
                    />
                  </ReferenceArrayInput>

                  <Grid container spacing={2} >
                    <Grid item xs="auto"  >
                      <Box sx={{ width: 500, maxWidth: '100%' }}>
                        <TextInput fullWidth {...rest} source={'module_' + MODULE_IDS.ID_FEEDBACK + '.sendgrid_feedback_new_template'} />
                      </Box>
                    </Grid>
                    <Grid item xs="auto"  >
                      <Box sx={{ width: 500, maxWidth: '100%' }}>
                        <TextInput fullWidth {...rest} source={'module_' + MODULE_IDS.ID_FEEDBACK + '.stats_filters_available'} label="resources.clients.fields.stats_filters_available" />
                      </Box>
                    </Grid>
                  </Grid >
                </div>
              )
            );
          }}
        </FormDataConsumer>

        <Separator />

        <BooleanInput
          source={"module_" + MODULE_IDS.ID_EXIT_INTERVIEW + ".enabled"}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              formData["module_" + MODULE_IDS.ID_EXIT_INTERVIEW] &&
              formData["module_" + MODULE_IDS.ID_EXIT_INTERVIEW].enabled && (
                <div className="child-items">
                  <ReferenceArrayInput
                    {...rest}
                    reference="inboxes"
                    source={
                      "module_" + MODULE_IDS.ID_EXIT_INTERVIEW + ".inbox_fields"
                    }
                    perPage={100}
                    sort={{ field: "id", order: "ASC" }}
                    filter={{ "moduleType.id": MODULE_IDS.ID_EXIT_INTERVIEW }}
                    onChange={() => setTimeout(() => setCount(count + 1), 250)}
                  >
                    <AutocompleteArrayInput
                      optionText="name"
                      filterToQuery={(searchText) => ({ name: searchText })}
                    />
                  </ReferenceArrayInput>

                  <Grid container spacing={2} >
                    <Grid item xs="auto"  >
                      <Box sx={{ width: 500, maxWidth: '100%' }}>
                        <TextInput fullWidth {...rest} source={'module_' + MODULE_IDS.ID_EXIT_INTERVIEW + '.sendgrid_new_exit_interview_template'} />
                      </Box>
                    </Grid>
                    <Grid item xs="auto"  >
                      <Box sx={{ width: 500, maxWidth: '100%' }}>
                        <TextInput fullWidth {...rest} source={'module_' + MODULE_IDS.ID_EXIT_INTERVIEW + '.stats_filters_available'} label="resources.clients.fields.stats_filters_available" />
                      </Box>
                    </Grid>
                  </Grid >
                </div>
              )
            );
          }}
        </FormDataConsumer>
        <Separator />
        <TextInput source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.whatsapp_sender_id'} />
        <TextInput source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.waba_key'} />
        <TextInput source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.whatsapp_template'} />
        <TextInput label="WhatsApp test phone" source={whatsAppPhoneFieldId} />
        <Button variant="outlined" color="secondary" size="small" onClick={sendWhatsAppTest}>Test WhatsApp</Button>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={whatsappTestSnackbar.message}
          autoHideDuration={3000}
          message={whatsappTestSnackbar.message}
        >
        </Snackbar>
        <Separator />
      </SimpleForm>
    </Create>
  );
};
const Separator = () => <div style={{ height: 5 }} />;

export default ClientCreate;
