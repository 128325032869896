import React from "react";
import {
    BooleanField,
    EmailField,
    Show,
    Tab,
    TabbedShowLayout,
    TextField,
    Error,
    Loading
} from 'react-admin';
import { useParams } from "react-router-dom";
import { useGetOne } from "react-admin";
import { MODULE_IDS } from "../../../../config/api";
import {ColorField} from "react-admin-color-picker";
import LogoField from "../../../atoms/logo-field/index";

const ClientsShow = (props) => {
    const { id } = useParams();
    const { data, loading, error } = useGetOne('clients', { id });

    if (loading) return <Loading />;
    if (error) return <Error />;

    return (
        <Show >
            <TabbedShowLayout>
                <Tab label="summary">
                    <TextField source="id" />
                    <TextField source="name" />
                    <BooleanField source="is_velora" label="Cliente Velora" />
                    <BooleanField source="is_velora_assesment" label="Velora Assesment" />
                    <EmailField source="email" />
                    <TextField source="email_from" />
                    <TextField source="max_users" />
                    <BooleanField source="sms_available" />
                    <BooleanField source="whatsapp_available" />
                    <TextField source="sms_prefix" />
                    <TextField source="sendgrid_token" />
                    <TextField source="whatsapp_voucher" />
                    <TextField source="data_retention_months" />
                    <TextField source="legal_name" />
                    <TextField source="nif" />
                    <TextField source="address" />
                    <TextField source="billing_address" />
                    <TextField source="city" />
                    <TextField source="cp" />
                    <TextField source="province" />
                    <TextField source="country" />
                    <BooleanField source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.enabled'} />
                    <BooleanField source={'module_' + MODULE_IDS.ID_EMPLOYEE_LINE + '.enabled'} />
                    <BooleanField source={'module_' + MODULE_IDS.ID_ON_BOARDING + '.enabled'} />
                    <BooleanField source={'module_' + MODULE_IDS.ID_FEEDBACK + '.enabled'} />
                    <BooleanField source={'module_' + MODULE_IDS.ID_EXIT_INTERVIEW + '.enabled'} />
                </Tab>

                <Tab  resource="clients" label="resources.clients.tabs.colors">
                    <ColorField source="text_color_principal" />
                    <ColorField source="text_color_secondary" />
                    <ColorField source="background_color" />
                    <LogoField record={data} source="logo" />
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
}

export default ClientsShow
