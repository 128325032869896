import { BASE_URL, API_ENTRYPOINT} from "../config/api";
import { setToken } from "../providers/storage/storage";
import { getAuthHeaders } from "../config/api";
import httpClient from "../providers/dataProvider/fetchQueries/httpClient";

export const authAdmin = async ({ username, password }) => {
    const body = JSON.stringify({ username, password });

    const request = new Request(`${BASE_URL}/authentication_token`, {
        method: 'POST',
        body: body,
        headers: getAuthHeaders(),
    });

    const response = await fetch(request);
    if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
    }

    const data = await response.json();
    setToken(data.token);
    return data;
}


export const removeUsersByClient = async (clientId, moduleId, fromDate, toDate, state) => {
    let states = '';
    for (let iterator = 0; iterator < state.length; iterator++) {
        states += '&state[]=' + state[iterator];
    }
    const url = `${BASE_URL}/api/admin/db/clear/client/${clientId}/module/${moduleId}?fromDate=${fromDate}&toDate=${toDate}${states}`

    const response = await fetch(url, {
        method: 'DELETE',
        headers: getAuthHeaders(),
    });

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
}

export const getLogoUrl = ({ slug }) => `${BASE_URL}${API_ENTRYPOINT}/clients/${slug}/logo`;

export const getLogo = async ({ slug }) => {
    const url = getLogoUrl({ slug });

    const response = await fetch(url, {
        method: 'GET',
        headers: getAuthHeaders(),
    });

    if (!response.ok) {
        console.error(`HTTP error! Status: ${response.status}`);
        throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const responseData = await response.json();

    return responseData;
}

export const getAvatarUrl = ({ resource, slug }) => `${BASE_URL}${API_ENTRYPOINT}/${resource}/${slug}/avatar`;

export const getAvatar = async ({ resource, slug }) => {
    const url = getAvatarUrl({ resource, slug });

    const response = await fetch(url, {
        method: 'GET',
        headers: getAuthHeaders(),
    });

    if (!response.ok) {
        console.error(`HTTP error! Status: ${response.status}`);
        throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const responseData = await response.json();

    return responseData;
}

export const sendWhatsApp = async ({ senderId, wabaToken, template, to, lang }) => {
    const response = await fetch(`${BASE_URL}${API_ENTRYPOINT}/client/whatsapp-test`, {
        method: 'POST',
        body: JSON.stringify({
            senderId: senderId,
            wabaToken: wabaToken,
            template: template,
            to: to,
            lang: lang
        }),
        headers: getAuthHeaders(),
    });

    if (!response.ok) {
        console.error(`HTTP error! Status: ${response.status}`);
        throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const responseData = await response.json();

    return responseData;
}

export const importBlacklist = async ({ moduleId, slug, file }) =>
    httpClient(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_ENTRYPOINT}/module/${moduleId}/client/${slug}/backlist/import_csv`, {
        method: 'POST',
        body: JSON.stringify({ file }),
    })


export const getClientsFormattedList = async () =>
    httpClient(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_ENTRYPOINT}/sms_reports/client_list`, {
        method: 'GET',
    })

export const getSmsReportsFetchData = async (from, to, clients = []) =>
    httpClient(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_ENTRYPOINT}/sms_reports/fetch_data`, {
        method: 'POST',
        body: JSON.stringify({ from, to, clients }),
    })

export const getClientStatisticsPanelFetchData = async (from, to, client) => {
    const queryString = new URLSearchParams({ from: from, to: to }).toString();

    const resourceUrl = `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_ENTRYPOINT}/client/${client}/dashboard?${queryString}`;
    return httpClient(resourceUrl, {
        method: 'GET'
    })
}

export const fetchCsStatisticsPanel = async (from, to, clients, endpoint = null) => {
    const queryString = new URLSearchParams({ from: from, to: to, clients: clients, endpoint: endpoint }).toString();

    const resourceUrl = `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_ENTRYPOINT}/customer_success/dashboard?${queryString}`;
    return httpClient(resourceUrl, {
        method: 'GET'
    })
}

export const getGeroChatbots = () => {
    return httpClient(`https://aifctlnhgyfebrmeonlrviuibi0xsaqf.lambda-url.eu-west-1.on.aws/stats/chatbots`, {
        method: 'GET',
    });
};


export const getGeroSessions = async (date_from, date_to, chatbots = [], variables = {}) => {
    return  httpClient(`https://aifctlnhgyfebrmeonlrviuibi0xsaqf.lambda-url.eu-west-1.on.aws/stats/sessions`, {
        method: 'POST',
        body: JSON.stringify({ date_from, date_to, chatbots, variables }),
    });
}


